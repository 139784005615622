import React, {useState} from "react";
import {Trans, useTranslation} from 'gatsby-plugin-react-i18next';
import Layout from "../../../../components/layout";
import Seo from "../../../../components/seo";
import {graphql} from 'gatsby';
import RevealerWrapper from '../../../../components/Revealer/RevealerWrapper';
import VideoIcons from '../../../../components/video-player/VideoIcons';
import ReactVideo360 from "../../../../components/video-player/ReactVideo360";
import {createMarkup} from '../../../../utils/utils';
import compareLeft from './images/NKCUE5GKPBN_360ROT_FF.jpg';
import compareRight from './images/NKCUE5GRFBN_360ROT_FF.jpg';
import {StaticImage} from "gatsby-plugin-image";
import posterKeypad from '../../../../images/support/posters/360s/cue/Cue_Standard_Keypad_Brushed.jpeg';
import posterRfid from '../../../../images/support/posters/360s/cue/Cue_Standard_RFID_Brushed.jpeg';


const Axis = (props) => {

  const {t} = useTranslation();
  const [currentVideoIndex, setCurrentVideoIndex] = useState(0);
  const [bcVideoId, setBcVideoId] = useState('https://cdn.mediavalet.com/usva/digilock/O46Fh5PJv02oNOkbwOe-9Q/nPCYhkY9jkiQoAQTUXc_lA/Original/Cue_Standard_Keypad_Brushed.mp4');
  const [currentPoster, setCurrentPoster] = useState(posterKeypad);

  function videoIconClickHandler(index, vid, poster) {
    setCurrentVideoIndex(index);
    setBcVideoId(vid);
    setCurrentPoster(poster)
  }
  return (
      <Layout>
        <Seo
            title={t('products_electronic_locks_cue')}
            description={t('des_products_electronic_locks_cue')}
        />
        <div className={'products electronic-locks cue'}>
          <section className="details">
            <div className="container">
              <StaticImage
                  src="../../../../images/logos/logo-cue.png"
                  loading={'lazy'}
                  width={252}
                  height={58}
                  quality={100}
                  formats={["auto", "webp", "avif"]}
                  alt="Cue logo"
              />
              <p dangerouslySetInnerHTML={{__html:t('cue_legacy_support_link')}} />
              <br/><br/>
              <h1><Trans>product_details</Trans></h1>
              <div className={'grid-two-col'}>

                <div className="grid-two-col-item">
                  <div className="revealer center-top">
                    <RevealerWrapper
                        compareLeft={compareRight}
                        compareRight={compareLeft}
                        width={200}
                        labelLeft={createMarkup(t('rfid'))}
                        labelRight={createMarkup(t('standard')+'<br />'+t('keypad'))}
                        clsName={'nextlock'}
                    />
                  </div>
                  <div className="product-copy">
                    <h2>
                      {t('interface')}
                    </h2>
                    <h3>
                      {t('front_unit')}
                    </h3>
                    <h3>
                      {t('standard')} {t('keypad')} : {t('rfid')}
                    </h3>
                    <p className={'pad-top'}>
                      {t('cue_interface_copy')}
                    </p>
                    <h3>
                      {t('power')} | 4 AA {t('batteries')}
                    </h3>
                    <p className={'pad-top'}>
                      {t('interface_power_copy_2')}*
                    </p>
                    <p className={'footnote'}>
                      <em>
                        *{t('based_on_average_usage')}
                      </em>
                    </p>
                  </div>
                </div>
                <div className="grid-two-col-item">
                  <div className="video-360">
                    <ReactVideo360 vURL={bcVideoId} poster={currentPoster} />
                  </div>
                  <div className="product-copy">
                    <h2>
                      {t('body_style')}
                    </h2>
                    <h3>
                      {t('front_unit')}
                    </h3>
                    <h3>
                      {t('finish')} | {t('brushed_nickel')}
                    </h3>
                    <p>
                      {t('cue_body_finish_copy')}
                    </p>
                    <h3>
                      {t('body')} | {t('standard')}
                    </h3>
                    <p>
                      {t('cue_body_copy')}
                    </p>
                    <VideoIcons
                        currentVideoIndex={currentVideoIndex}
                        videoIconClickHandler={videoIconClickHandler}
                        toggleClassNames={
                          [
                            'icon-standard-keypad',
                            'icon-standard-touch'
                          ]
                        }
                        bcVideoIds={
                          [
                            'https://cdn.mediavalet.com/usva/digilock/O46Fh5PJv02oNOkbwOe-9Q/nPCYhkY9jkiQoAQTUXc_lA/Original/Cue_Standard_Keypad_Brushed.mp4',
                            'https://cdn.mediavalet.com/usva/digilock/Frx9qktTvUGxyA0dCa9EKA/3yHuumyhrk-cKrYGwXMO7A/Original/Cue_Standard_RFID_Brushed.mp4'
                          ]
                        }
                        posters={
                          [
                              posterKeypad,
                              posterRfid
                          ]
                        }
                    >&nbsp;</VideoIcons>
                  </div>
                </div>

              </div>
            </div>
          </section>
          <div className="section-delimeter container" />
          <section className="rear-units">
            <div className="container">
              <h1>{t('locking_options')}</h1>
              <div className="grid-two-col">
                <div className="grid-two-col-item">
                  <div>
                    <StaticImage
                        src={'../../../../images/locks/rear-units/5g-bolt.png'}
                        loading={'lazy'}
                        width={364}
                        height={247}
                        quality={100}
                        formats={["auto", "webp", "avif"]}
                        alt="Bolt rear unit"
                    />
                  </div>
                  <div>
                    <h2>
                      {t('deadlatch')}
                    </h2>
                    <h3>
                      {t('rear_unit')}
                    </h3>
                    <p>
                      {t('deadlatch_rear_unit_copy')}
                    </p>
                    <h3>
                      {t('assigned_use')}
                    </h3>
                    <p>
                      {t('deadlatch_assigned_use_copy')}
                    </p>
                  </div>
                </div>
                <div className="grid-two-col-item">
                  <div>
                    <StaticImage
                        src={'../../../../images/locks/rear-units/5g-latch.png'}
                        loading={'lazy'}
                        width={364}
                        height={247}
                        quality={100}
                        formats={["auto", "webp", "avif"]}
                        alt="Latch rear unit"
                    />
                  </div>
                  <div>
                    <h2>
                      {t('deadbolt')}
                    </h2>
                    <h3>
                      {t('rear_unit')}
                    </h3>
                    <p>
                      {t('deadbolt_rear_unit_copy')}
                    </p>
                    <h3>
                      {t('shared_use')}
                    </h3>
                    <p>
                      {t('deadbolt_shared_use_copy')}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <div className="section-delimeter container" />
          <section className="management">
            <div className="container">
              <h1>{t('management')}</h1>

              <div className="grid-two-col">
                <div className="grid-two-col-item">
                  <div>
                    <StaticImage
                        src={'../../../../images/locks/management/app-manage-keys-all.png'}
                        loading={'lazy'}
                        width={169}
                        height={192}
                        quality={100}
                        formats={["auto", "webp", "avif"]}
                        alt="Management keys"
                    />
                  </div>
                  <div>
                    <h2>
                      {t('key_management')}
                    </h2>
                    <p>
                      {t('key_management_copy')}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </Layout>
  );
};

export default Axis;
export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
